import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: '/signIn'
  },
  {
    path: '/signIn',
    name: 'SignInPage',
    component: () => import('../views/SignInPage.vue')
  },
  {
    path: '/signUp',
    name: 'SignUpPage',
    component: () => import('../views/SignUpPage.vue')
  },
  {
    path: '/singleSign',
    name: 'SingleSign',
    component: () => import('../views/SingleSign.vue')
  },
  {
    path: '/configPage',
    name: 'SSOConfigPage',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SSOConfigPage.vue')
  },
  {
    path: '/verification',
    name: 'VerificationPage',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/VerificationPage.vue')
  },
  {
    path: '/welcome',
    name: 'SuccessPage',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SuccessPage.vue')
  },
  {
    path: '/forget',
    name: 'ForgetPswPage',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ForgetPswPage.vue')
  },
  {
    path: '/changePsw',
    name: 'ChangePswPage',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ChangePswPage.vue')
  },
  {
    path: '/waitingPage',
    name: 'SSOWaiting',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SSOWaiting.vue')
  },
  {
    path: '/unlockedSuccess',
    name: 'UnlockedSuccess',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/UnlockedSuccess.vue')
  },
  {
    path: '/qrcode',
    name: 'WxQrCode',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/WxQrCode.vue')
  },
  {
    path: '/wxlogin',
    name: 'WxLogin',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/WxLogin.vue')
  },
  {
    path: '/deleteAccount',
    name: 'DeleteAccount',
    component: () => import('../views/DeleteAccount.vue')
  },
  {
    path: '/oauth',
    name: 'OAuthPage',
    component: () => import('../views/OAuthPage.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
