<template>
  <div class="tvu-oauth-page">
    <div class="ac marginB15">
      <img
        src="../assets/images/new-logo.png"
        width="42"
        alt=""
        class="logo">
    </div>
    <div class="oauth-sign">
      <div class="title flex-start-between">
        <p>Sign in to TVU</p>
        <div
          class="sign-btn"
          @click="routerPush('/signUp')">
          {{$t('signUp')}}
        </div>
      </div>
      <el-form
        :model="signForm"
        :rules="rules"
        ref="signForm">
        <el-form-item
          prop="account"
          class="account-form">
          <div class="account-icon cursor">
            <i class="iconfont icon-phone fs-20 marginR10"></i>
            <span class="line-icon"></span>
            <i class="iconfont icon-Mail fs-20"></i>
          </div>
          <el-input
            class="account-input"
            tabindex='1'
            autocomplete
            v-model="signForm.account"
            @keyup.enter.native="signInChanges"
            @change="changesInput"
            id="account"
            :placeholder="$t('emailPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="iconfont icon-Lock fs-22"
            tabindex='2'
            autocomplete
            type="password"
            v-model="signForm.password"
            show-password
            @keyup.enter.native="signInChanges"
            @change="changesInput"
            id="password"
            ref="password"
            :placeholder="$t('password')"></el-input>
        </el-form-item>
      </el-form>
      <div class="fs-12 gray marginB5 cursor">
        <span
          class="border"
          @click="routerPush('/forget')">{{$t('forgotPsd')}}</span>
      </div>
      <div class="fs-12 marginB60">
        <span class="gray">{{$t('needHelp')}}</span>
        <el-popover
          placement="bottom-start"
          :title="$t('contactUs')"
          width="240"
          trigger="click">
          <p class="marginB10">{{$t('helpWay')}}</p>
          <p class="primary">
            <a
              href="mailto:support@tvunetworks.com">support@tvunetworks.com.</a>
            <span
              @click="sendEmail">support@tvunetworks.com.</span>
          </p>
          <span
            slot="reference"
            class="primary border cursor">{{$t('contact')}}</span>
        </el-popover>
      </div>
      <el-button
        v-repeat-click="{event:signInChanges}"
        class="sign-submit-btn"
        :loading="isLoading">
        {{$t('signIn')}}
      </el-button>
      <div
        class="fs-13 agree-info"
        style="margin-top: 35px;">
        {{$t('signInType')}}{{$t('agreeInfo')}}
        <span
          style="white-space: nowrap;"
          class="border cursor agree-item"
          @click="jumpToPolicy(2)">{{$t('termsInfo')}}</span>
        {{$t('and')}}
        <span
          class="border cursor agree-item"
          @click="jumpToPolicy(1)">{{$t('policyInfo')}}</span>
      </div>
      <div
        class="lang-footer">
        <span class="title">{{ $t("changeLang") }}：</span>
        <el-dropdown
          class="langSelector cursor"
          trigger="click"
          @command="changeLang">
          <span class="el-dropdown-link">
            <img
              :src="lang.src"
              alt=""
              style="margin-right: 5px"/>
            <span class="langText">{{ lang.label }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            class="lang"
            slot="dropdown">
            <el-dropdown-item
              v-for="item in options"
              :key="item.value"
              :command="item">
              <img
                :src="item.src"
                alt=""
                style="margin-right: 5px"/>
              <span>{{ item.label }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from '@/assets/js/utils'
const sha512 = require('js-sha512')

export default {
  name: 'OAuthPage',
  data () {
    return {
      signForm: {
        account: '',
        password: ''
      },
      isLoading: false,
      options: [
        {
          value: 'zh',
          label: '中文',
          src: require('@/assets/images/🇨🇳.png')
        },
        {
          value: 'en',
          label: 'English',
          src: require('@/assets/images/🇺🇸.png')
        }
      ],
      lang: {},
      currentLang: 'en',
      userName: 'xxx'
    }
  },
  computed: {
    rules () {
      return {
        account: [
          { required: true, message: this.$t('emailOrTelSignTip'), trigger: 'blur' }
        ],
        password: [{ required: true, message: this.$t('passwordTip'), trigger: 'blur' }]
      }
    }
  },
  mounted () {
    this.setLang()
    this.$nextTick(() => {
      this.changesInput()
      if (!this.signForm.account && !this.signForm.password) {
        this.$refs.signForm.resetFields()
      }
    })
  },
  methods: {
    setLang () {
      // 设置默认语言
      this.currentLang =
        localStorage.getItem('lang') ||
        navigator.language ||
        navigator.userLanguage
      if (this.currentLang.indexOf('zh') > -1) {
        this.$i18n.locale = 'zh'
        this.lang = {
          value: 'zh',
          label: '中文',
          src: require('@/assets/images/🇨🇳.png')
        }
      } else {
        this.$i18n.locale = 'en'
        this.lang = {
          value: 'en',
          label: 'English',
          src: require('@/assets/images/🇺🇸.png')
        }
      }
    },
    changeLang (lang) {
      this.lang = lang
      this.currentLang = lang.value
      this.$i18n.locale = lang.value
      window.localStorage.setItem('lang', lang.value)
    },
    jumpToPolicy (type) {
      const network = localStorage.getItem('lang') === 'zh' ? 'cn' : 'com'
      if (type == 1) {
        window.open(`https://www.tvunetworks.${network}/company-privacy-notice/`, '_blank')
      } else {
        window.open(`https://www.tvunetworks.${network}/company-terms-and-conditions/`, '_blank')
      }
    },
    sendEmail () {
      // app端唤起邮箱
      const email = 'support@tvunetworks.com'
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端

      if (isAndroid) { // 安卓机型
        Android.SendMail(JSON.stringify({
          email: email
        }))
      } else { // ios机型
        window.webkit.messageHandlers.SendMail.postMessage(JSON.stringify({
          email: email
        }))
      }
    },
    routerPush (path) {
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = path + '?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push(path)
      }
    },
    changesInput () {
      const passwordValue = $('#password').val()
      const accountValue = $('#account').val()
      if (passwordValue != '' && this.signForm.password != passwordValue) {
        this.signForm.password = passwordValue
      }
      if (accountValue != '' && this.signForm.password != accountValue) {
        this.signForm.account = accountValue
      }
    },
    signInChanges () {
      if (this.isLoading) {
        return
      }
      this.$refs.signForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.isLoading = true
        const params = new URLSearchParams()
        params.append('username', this.signForm.account)
        params.append('password', sha512(this.signForm.password).toUpperCase())
        this.$axios.post('/login-service/oauth/login', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(res => {
          this.isLoading = false
          if (res.data.errorCode === '0x0') {
            this.isLoading = false
            // 登录成功，继续OAuth流程
            // const token = res.data.result
            // this.oauthChange(token)
            window.location.href = `/login-service/oauth/authorize?client_id=${getUrlParam('client_id')}&response_type=${getUrlParam('response_type')}&scope=${getUrlParam('scope')}&redirect_uri=${getUrlParam('redirect_uri')}`
          } else {
            this.$message.error(res.data.errorInfo)
          }
        }).catch(() => {
          this.isLoading = false
        })
      })
    },
    oauthChange (token) {
      this.$axios.get(`/login-service/oauth/authorize?client_id=${getUrlParam('client_id')}&response_type=${getUrlParam('response_type')}&scope=${getUrlParam('scope')}&redirect_uri=${getUrlParam('redirect_uri')}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
      })
    }
  }
}
</script>

<style scoped lang="less">
.tvu-oauth-page {
  background: #ffffff;
  padding: 60px 0;
  height: 100vh;

  .oauth-sign {
    background: #F6F8FA;
    width: 545px;
    margin: 0 auto;
    padding: 30px 50px;

    .title {
      font-size: 16px;
      color: #333333;
      margin-bottom: 30px;
      align-items: center;
    }
  }

  .account-form {
    position: relative;
    .account-icon {
      position: absolute;
      z-index: 9;
      color: #C0C4CC;
      left: 12px;
      top: 4px;
    }
  }

  .account-input .el-input__inner {
    padding-left: 86px;
  }

  .lang-footer {
    text-align: right;
    margin-top: 50px;

    .title {
      color: #bcbcbc;
      font-size: 16px;
    }
  }
}
</style>
